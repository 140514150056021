import { Classes } from "@blueprintjs/core";
import styled, { css } from "styled-components";

export const SearchBarWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  .${Classes.INPUT} {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const SelectWrapper = styled.div<{ $enabled: boolean }>`
  ${({ $enabled }) =>
    !$enabled &&
    css`
      opacity: 0.4;

      pointer-events: none;
    `}
`;

export const popoverProps = {
  matchTargetWidth: true,
  minimal: true,
};
