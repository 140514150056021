import { OrgId } from "@hex/common";
import React from "react";
import styled from "styled-components";

import { Routes } from "../../route/routes.js";
import { GlobeIcon } from "../icons/CustomIcons.js";
import { Avatar } from "../user/Avatar.js";

const ImageDiv = styled.div`
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  color: ${({ theme }) => theme.highlightColor};

  font-size: ${({ theme }) => theme.fontSize.EXTRA_LARGE};

  background-color: ${({ theme }) => theme.backgroundColor.MUTED};

  border: 1px solid ${({ theme }) => theme.borderColor.DEFAULT};

  border-radius: ${({ theme }) => theme.borderRadius};
`;

export interface OrgOrPublicAvatarProps {
  hasCustomAppIcon?: boolean;
  isPublicIcon?: boolean;
  orgId: OrgId;
  orgName: string;
  size?: number;
}

export const OrgOrPublicAvatar: React.ComponentType<OrgOrPublicAvatarProps> =
  React.memo(function OrgOrPublicAvatar({
    hasCustomAppIcon,
    isPublicIcon = false,
    orgId,
    orgName,
    size = 30,
  }) {
    if (isPublicIcon) {
      return (
        <ImageDiv>
          <GlobeIcon />
        </ImageDiv>
      );
    }
    const appIcon = hasCustomAppIcon ? Routes.APP_ICON.getUrl({ orgId }) : null;
    return appIcon != null ? (
      <Avatar
        active={true}
        altText={orgName}
        imageUrl={appIcon}
        shape="square"
        size={size}
        text={orgName}
      />
    ) : (
      <ImageDiv>{orgName.charAt(0)}</ImageDiv>
    );
  });
