import { Classes } from "@blueprintjs/core";
import {
  ALL_COLLECTION_ROLES,
  CollectionRole,
  DOCS_LINKS,
  collectionRoleDescription,
  humanReadableCollectionRole,
} from "@hex/common";
import React, { ReactNode, useCallback } from "react";
import styled from "styled-components";

import {
  HexButton,
  HexMenu,
  HexMenuFooter,
  HexPopover,
} from "../../hex-components";
import { useToggleState } from "../../hooks/useToggleState";
import { DocsLink } from "../common/DocsLink";
import { RoleMenuItem } from "../common/RoleMenuItem";
import { SingleChevronDownIcon } from "../icons/CustomIcons";

const Container = styled.div`
  width: 100%;
`;

const RoleButton = styled(HexButton)`
  justify-content: space-between;

  outline: none;

  ${({ small }) => small && `padding-right: 3px;`}
`;

const StyledMenu = styled(HexMenu)`
  min-width: 90px;
  max-width: 290px;
  padding: 7px;

  .${Classes.MENU_DIVIDER} {
    margin: 7px 8px;
  }
`;

export interface CollectionRoleDropdownProps {
  disabled?: boolean;
  selectedRole: CollectionRole;
  onSelectRole: (newRole: CollectionRole) => void;
  additionalActions?: ReactNode;
}

export const CollectionRoleDropdown: React.ComponentType<CollectionRoleDropdownProps> =
  React.memo(function CollectionRoleDropdown({
    additionalActions,
    disabled = false,
    onSelectRole,
    selectedRole,
  }) {
    const [isMenuOpen, , { setFalse: closeMenu, toggle: toggleMenuOpen }] =
      useToggleState(false);

    const selectRole = useCallback(
      (role) => {
        onSelectRole(role);
        closeMenu();
      },
      [onSelectRole, closeMenu],
    );

    return (
      <Container>
        <HexPopover
          captureDismiss={true}
          content={
            <>
              <StyledMenu>
                <>
                  {ALL_COLLECTION_ROLES.map((role, key) => (
                    <RoleMenuItem<CollectionRole>
                      key={key}
                      currentRole={selectedRole}
                      description={collectionRoleDescription(role)}
                      label={humanReadableCollectionRole(role)}
                      role={role}
                      onSelectRole={selectRole}
                    />
                  ))}
                  {additionalActions}
                </>
              </StyledMenu>
              <HexMenuFooter>
                <DocsLink to={DOCS_LINKS.CollectionsPermissions}>
                  Learn more about permissions
                </DocsLink>
              </HexMenuFooter>
            </>
          }
          disabled={disabled}
          isOpen={isMenuOpen}
          minimal={true}
          placement="bottom-end"
          onClose={closeMenu}
        >
          <RoleButton
            active={isMenuOpen}
            disabled={disabled}
            minimal={true}
            rightIcon={<SingleChevronDownIcon />}
            small={true}
            onClick={toggleMenuOpen}
          >
            {humanReadableCollectionRole(selectedRole)}
          </RoleButton>
        </HexPopover>
      </Container>
    );
  });
