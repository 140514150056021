import styled from "styled-components";

export const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  width: 100%;
  height: 100%;

  backdrop-filter: blur(2px);
`;
