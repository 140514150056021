import {
  CustomElement,
  Descendant,
  FormattedText,
  HexVersionAtomicOperation,
  RichTextDocument,
  notEmpty,
} from "@hex/common";

import { CellContentsMP } from "../../../redux/slices/hexVersionMPSlice.js";

import {
  CellItem,
  MatchIndex,
  PROJECT_REPLACE_MULTIPLAYER_CONFIG_MAP,
  cleanSearchTerm,
} from "./utils";

/**
 * Recursive internal method that replaces all children RichText nodes. This is used for the 'Replace All' feature.
 */
function traverseAndReplaceRichtext(
  regex: RegExp,
  replaceWith: string,
  children: Descendant[],
): Descendant[] | null {
  return children.map((child: Descendant) => {
    if (FormattedText.guard(child)) {
      return {
        ...child,
        text: child.text.replace(regex, replaceWith),
      };
    }
    if (CustomElement.guard(child)) {
      const updatedChildren = (
        traverseAndReplaceRichtext(regex, replaceWith, child.children) ?? []
      ).filter(notEmpty);
      return {
        ...child,
        children: updatedChildren as any,
      };
    }

    return child;
  });
}

/**
 * Method used to traverse and replace all matching rich text search items.
 *
 * The method traverses the tree and replaces each child with the updated content.
 */
export function replaceAllForRichtext({
  caseMatch,
  exactWordMatch,
  replaceString,
  replaceWith,
  richTextDoc,
}: {
  richTextDoc: RichTextDocument;
  replaceString: string;
  replaceWith: string;
  caseMatch: boolean;
  exactWordMatch: boolean;
}): RichTextDocument {
  const cleanedSearchTerm = cleanSearchTerm(replaceString, exactWordMatch);
  const regex = new RegExp(cleanedSearchTerm, caseMatch ? "g" : "gi");

  return richTextDoc.map((element) => ({
    ...element,
    children: traverseAndReplaceRichtext(regex, replaceWith, element.children),
  })) as RichTextDocument;
}

/**
 * Recursive internal method used to assign each Rich text node with a lineSource and path to the rich text element.
 */
function addRichTextElementsRecursively(
  descendant: Descendant,
  addedElements: RichTextSearchableItem[],
  currentPath: number[],
): void {
  if (FormattedText.guard(descendant)) {
    addedElements.push({
      lineSource: descendant.text as string,
      richTextElementPath: [...currentPath],
    });
    return;
  }
  if (CustomElement.guard(descendant)) {
    descendant.children.forEach((child, idx) => {
      addRichTextElementsRecursively(child, addedElements, [
        ...currentPath,
        idx,
      ]);
    });
    return;
  }
}

interface RichTextSearchableItem {
  lineSource: string;
  richTextElementPath: number[];
}
/**
 * Iterate through the rich document and create a cellItem for EACH text node.
 * Note that this is different than what we do for the other plain text implementation where we would be able to search
 * more than one line.
 */
export function createRichTextElementsForSearch(
  richText: RichTextDocument,
): RichTextSearchableItem[] {
  const cellItems: RichTextSearchableItem[] = [];
  richText.forEach((element, idx) => {
    addRichTextElementsRecursively(element, cellItems, [idx]);
  });
  return cellItems;
}

type ReplaceAtPathProps = {
  node: Descendant;
  path: number[];
  depth: number;
  match: MatchIndex;
  replaceString: string;
};

function replaceAtPath({
  depth,
  match,
  node,
  path,
  replaceString,
}: ReplaceAtPathProps): Descendant {
  // Found the level in the traversal that we wish to replace.
  if (depth === path.length) {
    if (FormattedText.guard(node)) {
      const text = node.text;
      const updatedText =
        text.substring(0, match.startIndex) +
        replaceString +
        text.substring(match.endIndex);
      return {
        ...node,
        text: updatedText,
      };
    }
    return node;
  } else if (CustomElement.guard(node) && node.children.length > path[depth]) {
    const childIndex = path[depth];
    const updatedChildren = node.children.map((child, idx) =>
      idx === childIndex
        ? replaceAtPath({
            node: child,
            path,
            depth: depth + 1,
            match,
            replaceString,
          })
        : child,
    );

    return {
      ...node,
      children: updatedChildren,
    } as Descendant;
  }

  return node;
}

/**
 * Traversal method that is able to replace all rich text at a given path.
 */
export function replaceAtRichTextPath(
  item: CellItem,
  cellContents: CellContentsMP,
  replaceString: string,
): HexVersionAtomicOperation | null {
  const { cellType, lineIndex, match, richTextElementPath, type } = item;
  const richText =
    "richText" in cellContents
      ? (cellContents.richText as RichTextDocument)
      : null;
  if (
    richTextElementPath?.length == null ||
    richText == null ||
    lineIndex == null ||
    match == null
  ) {
    return null;
  }

  const updatedRichText = richText.map((element, idx) => {
    if (idx === richTextElementPath[0]) {
      return {
        ...element,
        ...replaceAtPath({
          node: element,
          path: richTextElementPath,
          depth: 1,
          match,
          replaceString,
        }),
      };
    }
    return element;
  });

  const replaceItemConfig = PROJECT_REPLACE_MULTIPLAYER_CONFIG_MAP[cellType];
  if (replaceItemConfig && replaceItemConfig[type]) {
    return (
      replaceItemConfig[type].updateCellContents?.({
        cellContents,
        cellId: item.cellId,
        nextSource: null,
        nextRichText: updatedRichText,
      }) ?? null
    );
  }
  return null;
}
