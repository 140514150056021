import { MouseEvent, useCallback } from "react";

import { useDispatch, useSelector } from "../../../redux/hooks";
import {
  closeCreateComponentSidebar,
  createComponentSidebarSelectors,
} from "../../../redux/slices/logicViewSlice";
import { useProjectContext } from "../../../util/projectContext";

export const useComponentSidebar = (): {
  createComponentSidebarOpen: boolean;
  closeComponentSidebar: (e: MouseEvent) => void;
} => {
  const projectContext = useProjectContext({ safe: true });
  const dispatch = useDispatch();

  const createComponentSidebarOpen = useSelector(
    (state) =>
      projectContext != null &&
      createComponentSidebarSelectors(
        projectContext.hexVersionId,
      ).selectCreateComponentSidebarOpen(state),
  );

  const closeComponentSidebar = useCallback(
    (e: MouseEvent) => {
      if (projectContext != null) {
        e.stopPropagation();
        e.preventDefault();
        dispatch(closeCreateComponentSidebar(projectContext.hexVersionId));
      }
    },
    [dispatch, projectContext],
  );

  return { createComponentSidebarOpen, closeComponentSidebar };
};
